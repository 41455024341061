import React, { Component } from "react";
import ImageGallery from 'react-image-gallery';

class Slider extends Component {

    onSlideClick = (event) => {
      let url = '/static' + event.target.src.split('/static')[1];
      let href = this.props.slides.filter(s => s.original === url)[0].href;
      window.location.href = href;
    }

    render() {
      return (
        <>
          <div className={"slider-container"}>
            <ImageGallery 
              items={this.props.slides}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              onClick={this.onSlideClick}/>
          </div>
        </>
      );
    }
}

export default Slider;
