import React, {Component} from 'react';

import Layout from '../layout';
import PostHome from '../post-home';
import Slider from '../slider';
import SEO from '../seo';

class HomePage extends Component {

    firstSlides = [];
    // secondSlides = [];
    
    firstThree = [];
    firstNine = [];
    lastNine = [];

    constructor(props) {
        super(props);
        let stickyPosts = [];
        let filterout_ids = [];
        this.props.data.forEach(p => {
            if(p.sliderImage.showsInFirstSlider && p.sliderImage.image && p.sliderImage.image.sourceUrl) {
                this.firstSlides.push({
                    original: p.sliderImage.image.localFile.childImageSharp.fluid.src,
                    href: p.uri
                });
                filterout_ids.push(p.id);
            }
            if (p.isSticky) {
                stickyPosts.push(p);
                filterout_ids.push(p.id);
            }
        });
        let normal_posts = this.props.data.filter(p => !filterout_ids.includes(p.id));
        let sorted_posts = stickyPosts.concat(normal_posts);

        this.firstThree = sorted_posts.slice(0, 3);
        this.firstNine = sorted_posts.slice(3, 12);
        this.lastNine = sorted_posts.slice(12, 21);
        
    }

    renderTopPost = (post) => {
        return (<PostHome key={post.id} id={post.id} post={post}/>);
    }

    renderBottomPost = (post) => {
        return (<PostHome key={post.id} id={post.id} post={post} bottom={true}/>);
    }

    render() {
        return(
            <Layout>
                <SEO title={`Revista Oleada`}/>
                {this.firstThree.map(this.renderTopPost)}
                <Slider slides={this.firstSlides}></Slider>
                {this.firstNine.map(this.renderBottomPost)}
                {this.lastNine.map(this.renderBottomPost)}
            </Layout>
        )
    }

}

export default HomePage;
