import React from "react"
import { graphql } from "gatsby"
import HomePage from "../components/home-page";

export default ({ data }) => <HomePage data={data.allWpPost.nodes}/>

export const query = graphql`
{
  allWpPost(sort: { fields: dateGmt, order: DESC }, limit: 50) {
    nodes {
      uri
      id
      author {
        node {
          id
          avatar {
            url
          }
          customProfileImage
          name
        }
      }
      categories {
        nodes {
          id
          name
          slug
        }
      }
      title
      featuredImage {
        node {
          id
          sourceUrl
          localFile {
            childImageSharp {
              id
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      sliderImage {
        showsInFirstSlider
        image {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      isSticky
      autorxs {
        authors {
          name
          customProfileImage
        }
      }
      copete {
        copete
      }
    }
  }
}
`
